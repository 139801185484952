<template>
  <div id="index">
    <div class="main">
      <div class="index-top flex between">
        <div class="index-top-left">
          <div class="top-lef-tit flex between">
            <div class="flex top-lef-tit1 center">
              <img src="@/assets/icon1.png" />公告公示
            </div>
            <div class="flex top-lef-tit2 center">
              更多<i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="top-lef-cen flex between">
            <div class="lef-cen-le">
              <el-carousel
                indicator-position="outside"
                :loop="true"
                height="224px"
              >
                <el-carousel-item
                  v-for="(item, index) in SwiperList"
                  :key="index"
                >
                  <img :src="item.path" />
                  <div class="item-text" v-if="item.title!=''">{{ item.title }}</div>
                </el-carousel-item>
                <!-- <el-carousel-item>
                  <img src="@/assets/swiper.png" />
                  <div class="item-text">555</div>
                </el-carousel-item>
                <el-carousel-item>
                  <img src="@/assets/swiper.png" />
                  <div class="item-text">555</div>
                </el-carousel-item> -->
              </el-carousel>
            </div>
            <div class="lef-cen-ri">
              <div
                class="no-list flex between center pointer"
                v-for="(item, index) in noList"
                :key="index"
                @click="toInfo(item.id)"
              >
                <div class="list-yuan"></div>
                <div class="list-text">
                  {{ item.title }}
                </div>
                <div class="list-time">{{ item.create_time }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="index-top-right">
          <div class="top-ri-tit">网站快速通道</div>
          <div class="top-ri-con">
            <div class="ri-con-nav flex pointer" @click="toUrl('/exam')">
              <img src="@/assets/a-bg.png" class="nav-bg" />
              <img src="@/assets/a-1.png" class="nav-icon" />
              <div class="con-nav-tit">
                <div class="con-nav-tit1">考试报名</div>
                <div class="con-nav-tit2">Examination registration</div>
              </div>
            </div>
            <div class="ri-con-nav flex pointer" @click="toUrl('/print')">
              <img src="@/assets/a-bg.png" class="nav-bg" />
              <img src="@/assets/a-2.png" class="nav-icon" />
              <div class="con-nav-tit">
                <div class="con-nav-tit1">打印准考证</div>
                <div class="con-nav-tit2">Print admission ticket</div>
              </div>
            </div>
            <div class="ri-con-nav flex pointer" @click="toUrl('/score')">
              <img src="@/assets/a-bg.png" class="nav-bg" />
              <img src="@/assets/a-3.png" class="nav-icon" />
              <div class="con-nav-tit">
                <div class="con-nav-tit1">成绩查询</div>
                <div class="con-nav-tit2">Score query</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="index-bottom flex between">
        <div class="bto-left">
          <div class="bto-left-item">
            <div class="top-lef-tit flex between">
              <div class="flex top-lef-tit1 center">
                <img src="@/assets/icon2.png" />报考指南
              </div>
            </div>
            <div class="top-lef-con flex between pointer" v-for="(item,index) in imgList2" :key="index" @click="toUrl('/finger')">
              <img :src="item.path" />
              <div class="top-lef-con-ri">
                <div class="lef-con-ri-tit">
                  {{item.title}}
                </div>
                <!-- <div class="lef-con-ri-time">2022-04-18</div> -->
              </div>
            </div>
          </div>
          <div class="bto-left-item" style="margin-top: 20px">
            <div class="top-lef-tit flex between">
              <div class="flex top-lef-tit1 center">
                <img src="@/assets/icon4.png" />联系我们
              </div>
            </div>
            <div class="top-lef-con flex between pointer" v-for="(item,index) in imgList3" :key="index" @click="toUrl('/contact')">
              <img :src="item.path" />
              <div class="top-lef-con-ri">
                <div class="lef-con-ri-tit">
                  {{item.title}}
                </div>
                <!-- <div class="lef-con-ri-time">2022-04-18</div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="bto-center">
          <div class="top-lef-tit flex between">
            <div class="flex top-lef-tit1 center">
              <img src="@/assets/icon3.png" />疑难解答
            </div>
            <div class="flex top-lef-tit2 center">
              更多<i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="lef-cen-ri">
            <div
              class="no-list flex between center"
              v-for="(item, index) in noList2"
              :key="index"
              @click="toInfo(item.id, 1)"
            >
              <div class="list-yuan"></div>
              <div class="list-text">
                {{ item.title }}
              </div>
              <div class="list-time">{{ item.create_time }}</div>
            </div>
          </div>
        </div>
        <div class="bto-right">
          <img :src="item.path" v-for="(item, index) in imgList" :key="index" />
          <!-- <img src="@/assets/swiper.png" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { articleLists, positionList, positionDetail } from "@/api/apiData";
// import Details from "./details";
import Empty from "@/assets/js/empty";
export default {
  // components: { Details },
  data() {
    return {
      noList: [],
      noList2: [],
      SwiperList: [],
      imgList: [],
      imgList2: [],
      imgList3: [],
    };
  },
  computed: {},
  mounted() {
    this.$bus.emit("navS", 1);
    this.getNotice();
    this.getNotice2();
    this.getSwiper();
    this.getImg();
  },
  methods: {
    // 轮播图
    getSwiper() {
      positionList({ posid: 3 }).then((res) => {
        if (res.code == 1) {
          this.SwiperList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 图片
    getImg() {
      positionList({ posid: 4 }).then((res) => {
        if (res.code == 1) {
          this.imgList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
      positionList({ posid: 1 }).then((res) => {
        if (res.code == 1) {
          this.imgList2 = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
      positionList({ posid: 2 }).then((res) => {
        if (res.code == 1) {
          this.imgList3 = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 公示公告
    getNotice() {
      let obj = {
        page: 1,
        size: 10,
        is_index: 1,
        channel_id: 1,
      };
      articleLists(obj).then((res) => {
        if (res.code == 1) {
          this.noList = res.data.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 疑难解答
    getNotice2() {
      let obj = {
        page: 1,
        size: 10,
        is_index: 1,
        channel_id: 2,
      };
      articleLists(obj).then((res) => {
        if (res.code == 1) {
          this.noList2 = res.data.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    toUrl(u) {
      this.$router.push(u);
    },
    // 跳转详情
    toInfo(id, n) {
      this.$router.push({
        path: "/noticeInfo",
        query: { id: id, type: n },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.index-top {
  padding-top: 15px;
  .index-top-left {
    width: 72%;
    .top-lef-cen {
      .lef-cen-le {
        width: 45%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .item-text {
          position: absolute;
          bottom: 0;
          background: #2121216b;
          color: white;
          width: 100%;
          padding: 5px 15px;
          font-size: 14px;
        }
      }
      .lef-cen-ri {
        width: 53%;
      }
    }
  }
  .index-top-right {
    width: 295px;
    .top-ri-tit {
      font-size: 14px;
      background: #58beff;
      color: white;
      width: fit-content;
      padding: 5px 10px;
    }
    .top-ri-con {
      background: #e5f5ff;
      padding: 18px 20px;
      .ri-con-nav {
        position: relative;
        width: 255px;
        height: 70px;
        margin-bottom: 20px;
        .nav-bg {
          width: 100%;
          height: 100%;
          position: absolute;
        }
        .nav-icon {
          width: 40px;
          height: 40px;
          position: absolute;
          left: 16px;
          top: 16px;
        }
        .con-nav-tit {
          position: absolute;
          color: white;
          top: 15px;
          left: 72px;
          width: 80%;
          .con-nav-tit1 {
            font-size: 18px;
          }
          .con-nav-tit2 {
            font-size: 10px;
          }
        }
      }
      .ri-con-nav:nth-child(3) {
        margin-bottom: 0;
      }
    }
  }
}
.top-lef-tit {
  border-bottom: solid 1px #f2f8f9;
  padding: 10px 0 5px;
  margin-bottom: 10px;
  .top-lef-tit1 {
    font-weight: bold;
    font-size: 16px;
    img {
      width: 22px;
      margin-right: 10px;
    }
  }
  .top-lef-tit2 {
    font-size: 11px;
    color: #cfcbcb;
  }
}
.no-list {
  border-bottom: dashed 1px #e8e8e8;
  padding: 10px 0;
  color: #6a6a6a;
  .list-yuan {
    width: 8px;
    height: 8px;
    background: #0089e1;
    border-radius: 50%;
  }
  .list-text {
    color: #6a6a6a;
    font-size: 14px;
    width: 68%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .list-time {
    color: #cfcbcb;
    font-size: 12px;
  }
}
.index-bottom {
  margin-top: 20px;
  .bto-left {
    width: 33%;
    .top-lef-con {
      position: relative;
      img {
        width: 45%;
        height: 109px;
        display: block;
      }
      .top-lef-con-ri {
        width: 50%;
        .lef-con-ri-tit {
          font-size: 14px;
        }
        .lef-con-ri-time {
          position: absolute;
          font-size: 12px;
          color: #cfcbcb;
          text-align: right;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
  .bto-center {
    width: 36%;
  }
  .bto-right {
    width: 28%;
    img {
      display: block;
      width: 100%;
      height: 173px;
    }
    img:nth-child(1) {
      margin-bottom: 10px;
    }
  }
}
</style>